import { motion } from "framer-motion";
import web_client from "../../assets/home/web-client.webp";
import { FaCheck } from "react-icons/fa";

export default function AtlantisWEBClient() {
  return (
    <div className="h-auto py-20 bg-customGray">
      <div className="flex flex-col lg:flex-row items-center justify-center gap-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center w-screen lg:w-1/3 px-5 lg:px-0"
        >
          <h1 className="text-2xl mt-5 font-semibold text-center text-customBlue">
            Atlantis.WEB Client
          </h1>
          <h1 className="text-3xl font-semibold mt-2 text-customDarkText"></h1>
          <p className="text-customDarkText mt-5 text-justify">
            Atlantis.WEB Client is a comprehensive solution for managing every
            aspect of your spa operations directly from your tablets and
            smartphones. Designed to empower spa owners and staff on-the-go, our
            mobile-optimized web client provides the tools and features needed
            to streamline workflows and drive business growth from anywhere, at
            any time.
          </p>
          <ul className="mt-5 flex flex-col gap-2">
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Calendar and Scheduling
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">Packages</p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Customer Management
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Staff Management
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Integrated Point-of-Sales (POS)
              </p>
            </li>
          </ul>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center items-center w-screen lg:w-1/3"
        >
          <img
            src={web_client}
            alt="Atlantis Web Client"
            height={500}
            width={500}
          />
        </motion.div>
      </div>
    </div>
  );
}
