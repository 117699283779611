"use client";
import { motion } from "framer-motion";
import workbench from "../../assets/home/win-client.webp";
import { FaCheck } from "react-icons/fa";

export default function AtlantisCORE() {
  return (
    <div className="h-auto py-20 bg-customGray">
      <div className="flex flex-col lg:flex-row items-center justify-center gap-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col  justify-center w-screen lg:w-1/3 px-5 lg:px-0"
        >
          <h1 className="text-2xl mt-5 text-center font-semibold text-customBlue">
            Atlantis.NET SPA Reservation System
          </h1>
          <h1 className="text-3xl font-semibold mt-10 text-customDarkText"></h1>
          <p className="text-customDarkText mt-5 text-justify">
            The Atlantis.Net SPA Reservation System serves as the core module
            within Atlantis.SPA Management Enterprise, encompassing a
            comprehensive array of features to optimize spa operations.
          </p>
          <ul className="mt-5 flex flex-col gap-2">
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Calendar and Scheduling
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Customer Management
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">Packages</p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Memberships
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Retails & Inventory
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Gift Cards & Vouchers
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Promotional Policies
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Staff Management
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Invoicing
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Integrated Point-of-Sales (POS)
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Recommendations
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Reporting & Analytics
              </p>
            </li>
          </ul>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center items-center w-screen lg:w-1/3"
        >
          <img
            src={workbench}
            alt="Atlantis Web Client"
            height={500}
            width={500}
          />
        </motion.div>
      </div>
    </div>
  );
}
