import { motion } from "framer-motion";
import survey from "../../assets/home/survey.webp";

export default function AtlantisSurvey() {
  return (
    <div className="h-auto py-20 bg-white">
      <div className="flex flex-col lg:flex-row items-center justify-center gap-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center items-center w-screen lg:w-1/3"
        >
          <img
            src={survey}
            alt="Atlantis Web Client"
            height={400}
            width={400}
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center w-screen lg:w-1/3 px-5 lg:px-0"
        >
          <h1 className="text-2xl mt-5 font-semibold text-center text-customBlue">
            Atlantis.Survey
          </h1>
          <h1 className="text-3xl font-semibold mt-2 text-customDarkText"></h1>
          <p className="text-customDarkText mt-5 text-justify">
            Atlantis.Survey is a dynamic mobile web application designed to
            streamline the completion of diverse surveys, ranging from
            consultation forms to medical questionnaires and feedback forms.
            <br />
            &nbsp;Offering unlimited customization options for different survey
            types and languages, Atlantis.Survey empowers users to tailor
            surveys to their specific needs and preferences. With seamless
            integration with Atlantis.Net, users can effortlessly modify survey
            templates, ensuring adaptability to evolving requirements and
            preferences.
            <br />
            &nbsp; Whether collecting client feedback, or gathering medical
            information, Atlantis.Survey provides a user-friendly platform that
            simplifies the survey completion process and enhances data
            collection efficiency while completed surveys are store in the
            customer's folder in Atlantis, ensuring easy access and organization
            of survey data for future reference and analysis.
          </p>
          {/* <ul className="mt-5 flex flex-col gap-2">
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Unlimited Questionnaire Types (Custom Consultation Form -
                Feedback Form - Medical Questionnaire)
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Parametric Customization
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Accessibility via Atlantis.Net & Atlantis.Web Client
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Multilingual Support
              </p>
            </li>
          </ul> */}
        </motion.div>
      </div>
    </div>
  );
}
