import { motion } from "framer-motion";

export default function WhoWeAre() {
  return (
    <div className="flex items-center justify-center h-full py-20 md:py-32 bg-white">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.2, transition: 1.5 }}
        viewport={{ once: true }}
        className="text-center w-full px-5 md:w-1/2"
      >
        <h1 className="text-customDarkText text-3xl font-semibold mt-5">
          Who We Are
        </h1>
        <h1 className="text-customDarkText mt-5 font-light text-xl text-center px-5">
          Netsoft is a dynamic software development company dedicated to
          delivering innovative B2B solutions. Our flagship product,
          Atlantis.SPA Management, has been a trusted name in Spa Software
          industry since 1998, setting the standard for reliability, efficiency,
          and performance.
          {/* With over 25 years of expertise, Netsoft Technologies is a leading
          SaaS provider specializing in premium spa management. Our innovative
          solutions redefine industry standards, offering seamless operations
          and unparalleled guest experiences. Join us as we continue to shape
          the future of hospitality through cutting-edge technology. */}
        </h1>
      </motion.div>
    </div>
  );
}
