import AtlantisCORE from "../components/home/01_AtlantisCORE";
import AtlantisCRM from "../components/home/02_AtlantisCRM";
import AtlantisWEBClient from "../components/home/03_AtlantisWEBClient";
import AtlantisSurvey from "../components/home/04_AtlantisSurvey";
import AtlantisTherapist from "../components/home/05_AtlantisTherapist";
import AtlantisOnline from "../components/home/06_AtlantisOnline";
import Hero from "../components/home/Hero";
import OurCustomers from "../components/home/OurCustomers";
import Solution from "../components/home/Solution";
import SpaOnline from "../components/home/Spa";
import WhoWeAre from "../components/home/WhoWeAre";
import Footer from "../components/shared/Footer";
import Navbar from "../components/shared/Navbar";

export default function Home() {
  return (
    <>
      <Navbar />
      <Hero />
      <WhoWeAre />
      <SpaOnline />
      <Solution />
      <AtlantisCORE />
      <AtlantisCRM />
      <AtlantisWEBClient />
      <AtlantisSurvey />
      <AtlantisTherapist />
      <AtlantisOnline />
      <OurCustomers />
      <div id="contact">
        <Footer />
      </div>
    </>
  );
}
