import { motion } from "framer-motion";
import therapist from "../../assets/home/therapist2.webp";

export default function AtlantisTherapist() {
  return (
    <div className="h-auto py-20 bg-customGray">
      <div className="flex flex-col lg:flex-row items-center justify-center gap-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center w-screen lg:w-1/3 px-5 lg:px-0"
        >
          <h1 className="text-2xl mt-5 font-semibold text-center text-customBlue">
            Atlantis.Therapist
          </h1>
          <p className="text-customDarkText mt-5 text-justify">
            The Atlantis.Therapist module is a dedicated mobile web application
            designed to support therapists in managing their daily appointments
            effectively. With streamlined functionality, therapists can
            conveniently view their scheduled appointments for the day and mark
            them as completed upon fulfillment.
            <br />
            &nbsp; The module allows therapists to efficiently document their
            interactions with clients by adding comments and notes about each
            appointment, fostering detailed record-keeping and personalized
            care.
            <br /> &nbsp;Additionally, therapists can maintain a comprehensive
            list of suggestions provided to clients, promoting continuity of
            care and facilitating future discussions. With its user-friendly
            interface and focused features, Atlantis.Therapist empowers
            therapists to optimize their workflow and enhance client
            satisfaction.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center items-center w-screen lg:w-1/3"
        >
          <img
            src={therapist}
            alt="Atlantis Therapist"
            height={200}
            width={200}
          />
        </motion.div>
      </div>
    </div>
  );
}
