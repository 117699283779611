import microsoft from "../../assets/home/microsoft.webp";
import linkedin from "../../assets/home/linkedin.webp";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-customDarkBlue px-4 pt-16">
      {/* <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4"> */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center items-center pb-16 gap-5">
        <div>
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
            <span className="text-xl font-bold tracking-wide text-customLightText uppercase">
              Netsoft Technologies
            </span>
          </a>
          <div className="mt-6 lg:max-w-sm">
            <p className="mt-4 text-sm text-customLightText">
              Netsoft Technologies is a dynamic software development company
              dedicated to delivering innovative B2B solutions. Our flagship
              product, Atlantis.SPA Management Enterprise, has been a trusted
              name in the spa software industry since 1998, setting the standard
              for reliability, efficiency, and performance. With over two
              decades of experience and expertise, we are committed to
              continuously refining and enhancing Atlantis.SPA to meet the
              evolving needs of our clients worldwide
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-3">
          <img src={microsoft} alt="Microsoft Partner" height={50} width={50} />
          <h1 className="text-white text-lg font-bold leading-tight">
            Microsoft <br /> Partner
          </h1>
        </div>

        <div className="space-y-2 text-sm">
          <p className="text-base font-bold tracking-wide text-customLightText">
            Contacts
          </p>
          <div className="flex">
            <p className="mr-1 text-customLightText font-bold">Phone:</p>
            <p
              aria-label="Our phone"
              title="Our phone"
              className="transition-colors duration-300 text-customLightText hover:text-deep-purple-800"
            >
              +30 25510 20040
            </p>
          </div>
          <div className="flex">
            <p className="mr-1 text-customLightText font-bold">Email:</p>
            <p
              aria-label="Our email"
              title="Our email"
              className="transition-colors duration-300 text-customLightText hover:text-deep-purple-800"
            >
              info@netsoft.gr
            </p>
          </div>
          <div className="flex">
            <p
              href="https://www.google.com/maps"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Our address"
              title="Our address"
              className="transition-colors duration-300 text-customLightText hover:text-deep-purple-800"
            >
              <span className="text-customLightText font-bold">Address: </span>
              Leof. Dimokratias 288 <br /> Alexandroupoli 68100 - Greece
            </p>
          </div>
        </div>
        <div>
          <span className="text-base font-bold tracking-wide text-customLightText">
            Social
          </span>
          <div className="flex items-center mt-1 space-x-3">
            <a href="https://www.linkedin.com/company/netsoft-technologies-gr/">
              <img
                src={linkedin}
                alt="Atlantis Spa Dashboard"
                height={35}
                width={35}
              />
            </a>
          </div>
          <p className="mt-4 text-sm text-customLightText">
            Follow us on social media.
          </p>
        </div>
      </div>
      <div className="flex  justify-center pt-5 pb-10 border-t lg:flex-row">
        <p className="text-sm text-customLightText">
          © Copyright {currentYear} Netsoft Technologies All rights reserved.
        </p>
      </div>
    </div>
  );
}
