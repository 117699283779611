import { motion } from "framer-motion";
import { FaRegCalendarCheck, FaRegCalendarDays } from "react-icons/fa6";
import { BsFillPeopleFill, BsGraphUpArrow } from "react-icons/bs";
import { RiSurveyLine } from "react-icons/ri";
import { AiFillApi } from "react-icons/ai";

export default function Solution() {
  return (
    <div className="flex flex-col h-auto lg:h-auto py-20 items-center justify-center">
      <motion.h1
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="text-2xl text-center mt-5 font-semibold text-customBlue"
      >
        Atlantis.SPA Management Enterprise
      </motion.h1>
      <motion.h1
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="text-2xl md:text-3xl font-semibold w-screen px-5 lg:px-0 lg:w-1/3 text-center mt-2"
      >
        Modules
      </motion.h1>
      <p className="text-customDarkText mt-5 text-center w-2/3">
        Atlantis.SPA Management Enterprise comprises several integrated or
        add-on modules tailored to streamline spa operations comprehensively.
        These modules serve as extensions to the core system, offering enhanced
        functionality to meet specific operational needs.
      </p>
      <div className="flex flex-col lg:flex-row mt-20 px-5 lg:px-20 gap-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          className="bg-customGray py-5 px-10 flex flex-col gap-4 rounded-xl shadow-2xl shadow-gray-300 lg:w-1/3 h-auto"
        >
          <FaRegCalendarCheck size={30} color="#0074ff" />
          <h1 className="text-2xl font-semibold text-customDarkText">
            Atlantis.Net
          </h1>
          <p className="text-lg text-customDarkText">
            The Atlantis.Net SPA Reservation module provides a user-friendly
            interface to efficiently manage and organize the entire spa
            business.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          viewport={{ once: true }}
          className="bg-customGray py-5 px-10 flex flex-col gap-4 rounded-xl shadow-2xl shadow-gray-300 lg:w-1/3 h-auto"
        >
          <BsFillPeopleFill size={30} color="#0074ff" />
          <h1 className="text-2xl font-semibold text-customDarkText">
            Atlantis.CRM
          </h1>
          <p className="text-lg text-customDarkText">
            The Atlantis.CRM module offers a comprehensive customer relationship
            management solution designed to streamline client interactions.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          viewport={{ once: true }}
          className="bg-customGray py-5 px-10 flex flex-col gap-4 rounded-xl shadow-2xl shadow-gray-300 lg:w-1/3 h-auto"
        >
          <BsGraphUpArrow size={30} color="#0074ff" />
          <h1 className="text-2xl font-semibold text-customDarkText">
            Atlantis.Web
          </h1>
          <p className="text-lg text-customDarkText">
            Atlantis.Web is a versatile module that empowers staff to schedule
            appointments conveniently from tablets and smartphones, enhancing
            flexibility and productivity in spa operations.
          </p>
        </motion.div>
      </div>
      <div className="flex flex-col lg:flex-row mt-10 lg:mt-10 px-5 lg:px-20 gap-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.8 }}
          viewport={{ once: true }}
          className="bg-customGray py-5 px-10 flex flex-col gap-4 rounded-xl shadow-2xl shadow-gray-300 lg:w-1/3 h-auto"
        >
          <AiFillApi size={30} color="#0074ff" />
          <h1 className="text-2xl font-semibold text-customDarkText">
            Atlantis.Survey
          </h1>
          <p className="text-lg text-customDarkText">
            Atlantis.Survey is a valuable tool for businesses to collect
            essential information from customers.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1 }}
          viewport={{ once: true }}
          className="bg-customGray py-5 px-10 flex flex-col gap-4 rounded-xl shadow-2xl shadow-gray-300 lg:w-1/3"
        >
          <RiSurveyLine size={30} color="#0074ff" />
          <h1 className="text-2xl font-semibold text-customDarkText">
            Atlantis.Therapist
          </h1>
          <p className="text-lg text-customDarkText">
            The Atlantis.Therapist module allows therapists to view their
            appointments from their smartphone or tablet.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1.2 }}
          viewport={{ once: true }}
          className="bg-customGray py-5 px-10 flex flex-col gap-4 rounded-xl shadow-2xl shadow-gray-300 lg:w-1/3"
        >
          <FaRegCalendarDays size={30} color="#0074ff" />
          <h1 className="text-2xl font-semibold text-customDarkText">
            Atlantis.Online
          </h1>
          <p className="text-lg text-customDarkText">
            The Atlantis.Online module empowers customers to conveniently
            schedule appointments directly from their smartphones.
          </p>
        </motion.div>
      </div>
    </div>
  );
}
