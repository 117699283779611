import { motion } from "framer-motion";
import { FaCalendarAlt, FaBullhorn, FaFlag } from "react-icons/fa";
import {
  FaIdCard,
  FaBoxOpen,
  FaPeopleGroup,
  FaCalendarDay,
  FaThumbsUp,
} from "react-icons/fa6";
import { TbPackages, TbGiftCardFilled } from "react-icons/tb";
import { MdEmail } from "react-icons/md";
import { IoPeople } from "react-icons/io5";
import { VscGraph } from "react-icons/vsc";
import { AiFillApi } from "react-icons/ai";
import { RiSurveyFill } from "react-icons/ri";
import { BsFilePostFill, BsFillHousesFill } from "react-icons/bs";
import { IoReceipt } from "react-icons/io5";

export default function SpaOnline() {
  return (
    <div className="flex justify-center h-auto py-20 bg-customGray">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
        viewport={{ once: true }}
        className="flex flex-col justify-center text-center w-screen lg:w-2/3 px-5"
      >
        <h1 className="text-2xl mt-5 font-semibold text-customBlue">
          Atlantis.SPA Management Enterprise
        </h1>
        <h1 className="text-4xl font-semibold mt-2 text-customDarkText">
          Features
        </h1>
        <p className="text-customDarkText mt-5 text-center">
          Atlantis.SPA is a cloud-based enterprise solution for spa and leisure
          activity management. It offers features for appointment scheduling,
          resource allocation, inventory management, and reporting. Clients can
          book appointments online, while managers can handle staff schedules,
          monitor inventory, and access analytics for data-driven decisions.
          Atlantis.SPA is scalable and offers a user-friendly interface, making
          it ideal for spas worldwide.
        </p>
        {/* <p className="text-customDarkText mt-5 text-center">
          Atlantis.SPA offers a comprehensive set of features designed to
          streamline operations and enhance customer experiences. From
          appointment scheduling and resource allocation to inventory management
          and reporting, Atlantis empowers spa managers and staff to efficiently
          run their facilities while delivering exceptional service to clients.
        </p>
        <p className="text-customDarkText mt-5 text-center">
          One of the key features of Atlantis.SPA is its flexible booking
          system, which allows clients to easily schedule appointments online or
          through mobile applications. With customizable booking options and
          real-time availability updates, clients can book appointments at their
          convenience, reducing administrative overhead and improving customer
          satisfaction.
        </p>
        <p className="text-customDarkText mt-5 text-center">
          Additionally, Atlantis.SPA provides robust tools for managing staff
          schedules, optimizing resource utilization, and tracking performance
          metrics. Managers can assign tasks, track employee hours, and monitor
          productivity, ensuring smooth operations and optimal staffing levels
          at all times.
        </p>
        <p className="text-customDarkText mt-5 text-center">
          Furthermore, Atlantis.SPA includes integrated inventory management
          capabilities to track and replenish spa supplies efficiently. From
          skincare products to towels and robes, managers can monitor inventory
          levels, place orders, and receive notifications for low stock items,
          minimizing disruptions to daily operations.{" "}
        </p>
        <p className="text-customDarkText mt-5 text-center">
          Moreover, Atlantis.SPA offers comprehensive reporting and analytics
          features that provide valuable insights into business performance and
          trends. Managers can generate custom reports on key metrics such as
          revenue, client retention, and service utilization, enabling
          data-driven decision-making and strategic planning.
        </p>
        <p className="text-customDarkText mt-5 text-center">
          Whether managing a single spa location or a network of facilities,
          Atlantis.SPA offers scalability and flexibility to meet the unique
          needs of each business. With its intuitive interface, powerful
          features, and reliable cloud-based infrastructure, Atlantis.SPA is the
          preferred choice for spas and leisure facilities worldwide.
        </p> */}
        <div className="flex flex-col md:flex-row md:justify-center md:items-center gap-3 md:gap-40 mt-10 lg:px-0">
          <div className="flex flex-col gap-5">
            <div className="flex flex-row gap-2 items-center">
              <FaCalendarAlt color="#0074ff" size={30} />
              <p className="text-md font-light">Calendar & Scheduling</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaIdCard color="#0074ff" size={30} />
              <p className="text-md font-light">Customer Management</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaBoxOpen color="#0074ff" size={30} />
              <p className="text-md font-light">Packages</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <IoPeople color="#0074ff" size={30} />
              <p className="text-md font-light">Memberships</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <TbPackages color="#0074ff" size={30} />
              <p className="text-md font-light">Retails & Inventory</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <TbGiftCardFilled color="#0074ff" size={30} />
              <p className="text-md font-light">Gift Cards</p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-row gap-2 items-center">
              <FaBullhorn color="#0074ff" size={30} />
              <p className="text-md font-light">Promotional Policies</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaPeopleGroup color="#0074ff" size={40} />
              <p className="text-md font-light">Staff Management</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <MdEmail color="#0074ff" size={35} />
              <p className="text-md font-light">Email Marketing</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <VscGraph color="#0074ff" size={30} />
              <p className="text-md font-light">Report & Analytics</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaCalendarDay color="#0074ff" size={28} />
              <p className="text-md font-light">Onlne Reservations</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <IoReceipt color="#0074ff" size={28} />
              <p className="text-md font-light">Invoicing</p>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-row gap-2 items-center">
              <RiSurveyFill color="#0074ff" size={35} />
              <p className="text-md font-light">Surveys & Reviews</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <AiFillApi color="#0074ff" size={32} />
              <p className="text-md font-light">Integrations</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <BsFilePostFill color="#0074ff" size={28} />
              <p className="text-md font-light">Point Of Sales</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaFlag color="#0074ff" size={28} />
              <p className="text-md font-light">Multilingual</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <FaThumbsUp color="#0074ff" size={28} />
              <p className="text-md font-light">Recommendations</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <BsFillHousesFill color="#0074ff" size={28} />
              <p className="text-md font-light">Multiproperty</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-center gap-10 mt-10"></div>
        <div className="flex flex-col lg:flex-row justify-center gap-10 mt-10"></div>
        {/* <ul className="list-disc ml-5 mt-5 text-lg text-customDarkText">
          <li>Surveys & Reviews</li>
          <li>Integrations</li>
           <li>Atlantis.SPA Central Reservation System</li>
            <li>Atlantis.CRM Customer Reslationship Management</li>
            <li>Atlantis.STOCK Stock Management</li>
            <li>Atlantis.Web Client</li>
            <li>Atlantis.Web Survey</li>
            <li>Atlantis.Web Therapist</li>
            <li>Atlantis.WebAPI</li>
            <li>Atlantis.Terminal</li>
            <li>Atlantis.POS</li>
            <li>Atlantis.Web OnLine Reservations</li>
        </ul> */}
      </motion.div>
    </div>
  );
}
