import wallpaper from "../../assets/home/wallpaper.webp";

export default function Hero() {
  return (
    <section
      className="flex h-screen relative"
      style={{
        background: `linear-gradient(rgb(25 46 71), rgba(0, 0, 0, 0.7)), url(${wallpaper})`,
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-col text-center z-10 w-full lg:w-2/3">
        <h1 className="text-customLightText text-4xl md:text-5xl">
          Netsoft Technologies
        </h1>
        <h1 className="text-customLightText text-lg md:text-2xl font-light">
          Software & Web Development
        </h1>
        <h1 className="text-customLightText text-lg md:text-xl mt-4 mx-10 md:mx-0">
          Embark on a digital journey with Netsoft Technologies, where serenity
          meets innovation. As pioneers in spa management software we are
          committed to transforming the way you run your spa business.
        </h1>
      </div>
    </section>
  );
}
