"use client";
import { motion } from "framer-motion";
import { FaCheck } from "react-icons/fa";
import crm from "../../assets/home/crm.webp";

export default function AtlantisCRM() {
  return (
    <div className="h-auto py-20 bg-white">
      <div className="flex flex-col lg:flex-row items-center justify-center gap-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center items-center w-screen lg:w-1/3"
        >
          <img src={crm} alt="Atlantis Web Client" height={350} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center w-screen lg:w-1/3 px-5 lg:px-0"
        >
          <h1 className="text-2xl mt-5 font-semibold text-customBlue text-center">
            Atlantis.CRM
          </h1>
          {/* <h1 className="text-3xl font-semibold mt-2 text-customDarkText">
            Customer Relationship Management
          </h1> */}
          <p className="text-customDarkText mt-5 text-justify">
            Atlantis.CRM is designed to streamline your interactions with
            customers and prospects. With intuitive features and seamless
            integration, our CRM module empowers you to manage your customer
            relationships effectively and drive business growth.
          </p>
          <ul className="mt-5 flex flex-col gap-2">
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Campaign Management
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Automated Workflows
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Customer Segmentation Tools
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Activity Tracking (Telephone - SMS - Email)
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Offers - Contracts
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Email Templates with custom parameters
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Mass Email/SMS Generator
              </p>
            </li>
            <li className="flex flex-row gap-5 items-center">
              <FaCheck color="green" size={20} />
              <p className="text-lg font-light text-customDarkText">
                Reporting and Analytics
              </p>
            </li>
          </ul>
        </motion.div>
      </div>
    </div>
  );
}
