import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";

// Hotels
import aldemar from "../../assets/hotels/aldemar.webp";
import alexander from "../../assets/hotels/alexander.webp";
import aphrodite from "../../assets/hotels/aphrodite-hills.webp";
import grande_bretagne from "../../assets/hotels/grande-bretagne.webp";
import grecotel from "../../assets/hotels/grecotel.webp";
import santo from "../../assets/hotels/santo-maris-oia.webp";
import atrium from "../../assets/hotels/atrium.webp";
import atrium_prestige from "../../assets/hotels/atrium_prestige.webp";
import cretan_dream from "../../assets/hotels/cretan_dream.webp";
import divani from "../../assets/hotels/divani.webp";
import ella_resorts from "../../assets/hotels/ella_resorts.webp";
import grecotel_amirandes from "../../assets/hotels/grecotel_amirandes.webp";
import grecotel_cape_sounio from "../../assets/hotels/grecotel_cape_sounio.webp";
import grecotel_corfu_imperial from "../../assets/hotels/grecotel_corfu_imperial.webp";
import grecotel_olympia_riviera from "../../assets/hotels/grecotel_olympia_riviera.webp";
import idolo from "../../assets/hotels/idolo.webp";
import manna from "../../assets/hotels/manna.webp";
import mitsis_alila from "../../assets/hotels/mitsis_alila.webp";
import ostria from "../../assets/hotels/ostria.webp";
import seaside from "../../assets/hotels/seaside.webp";

export default function OurCustomers() {
  return (
    <div className="flex flex-col bg-customGray py-20">
      <div className="flex flex-col text-center">
        <motion.h1
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.2 }}
          viewport={{ once: true }}
          className="text-customDarkText text-3xl font-semibold"
        >
          Our Customers
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          viewport={{ once: true }}
          className="text-customDarkText mt-5 font-light text-xl text-center px-5"
        >
          Atlantis has an impressive client list that includes many of the
          world’s premier hotel groups.
        </motion.p>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1 }}
        viewport={{ once: true }}
        className="mt-10"
      >
        <Marquee
          autoFill={true}
          gradient={true}
          gradientColor="#f2f2f2"
          gradientWidth={200}
        >
          <img
            src={alexander}
            height={120}
            width={120}
            className="mr-3"
            alt="Alexander"
          />
          <img
            src={aldemar}
            height={100}
            width={100}
            className="mr-3"
            alt="Aldemar"
          />
          <img
            src={aphrodite}
            height={120}
            width={120}
            className="mr-3"
            alt="Aphrodite Hills"
          />
          <img
            src={grande_bretagne}
            height={120}
            width={120}
            className="mr-3"
            alt="Grande Bretagne"
          />
          <img
            src={grecotel}
            height={120}
            width={120}
            className="mr-3"
            alt="Grecotel"
          />
          <img
            src={divani}
            height={120}
            width={120}
            className="mr-3"
            alt="Divani"
          />
          <img
            src={santo}
            height={100}
            width={100}
            className="mr-3"
            alt="Santo"
          />
          <img
            src={atrium}
            height={180}
            width={180}
            className="mr-3"
            alt="Atrium"
          />
          <img
            src={atrium_prestige}
            height={100}
            width={100}
            className="mr-3"
            alt="Atrium Prestige"
          />
          <img
            src={ella_resorts}
            height={120}
            width={120}
            className="mr-3"
            alt="Ella Resorts"
          />
          <img
            src={grecotel_amirandes}
            height={120}
            width={120}
            className="mr-3"
            alt="Grecotel Amirandes"
          />
          <img
            src={grecotel_cape_sounio}
            height={120}
            width={120}
            className="mr-3"
            alt="Grecotel Cape Sounio"
          />
          <img
            src={grecotel_corfu_imperial}
            height={120}
            width={120}
            className="mr-3"
            alt="Grecotel Corfu Imperial"
          />
          <img
            src={grecotel_olympia_riviera}
            height={120}
            width={120}
            className="mr-3"
            alt="Grecotel Olympia Riviera"
          />
          <img
            src={idolo}
            height={120}
            width={120}
            className="mr-3"
            alt="Idolo"
          />
          <img
            src={manna}
            height={120}
            width={120}
            className="mr-3"
            alt="Manna"
          />
          <img
            src={mitsis_alila}
            height={180}
            width={180}
            className="mr-3"
            alt="Mitsis Alila"
          />
          <img
            src={ostria}
            height={120}
            width={120}
            className="mr-3"
            alt="Ostria"
          />
          <img
            src={seaside}
            height={120}
            width={120}
            className="mr-3"
            alt="Seaside"
          />
          <img
            src={cretan_dream}
            height={120}
            width={120}
            className="mr-3"
            alt="Cretan Dream"
          />
        </Marquee>
      </motion.div>
    </div>
  );
}
