import React, { useState } from "react";
import logo from "../../assets/home/logo.webp";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav
      className="bg-customGray fixed w-screen z-20 top-0 start-0 backdrop-blur-md border-b border-gray-500 py-2"
      style={{
        backgroundColor: "rgba(16,53,109, 0.8)",
        backdropFilter: "blur(10px)",
      }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <a href="/" className="flex-shrink-0">
              <img width={200} height="auto" src={logo} alt="Logo" />
            </a>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <a
                href="/"
                className="px-3 py-2 rounded-md text-sm font-medium text-white hover:text-gray-300"
              >
                Home
              </a>
              <a
                href="#contact"
                className="px-3 py-2 rounded-md text-sm font-medium text-white hover:text-gray-300"
              >
                About Us
              </a>
              <a
                href="#contact"
                className="px-3 py-2 rounded-md text-sm font-medium text-white hover:text-gray-300"
              >
                Contact
              </a>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 "
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a
              href="/"
              onClick={handleLinkClick}
              className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-300"
            >
              Home
            </a>
            <a
              href="#contact"
              onClick={handleLinkClick}
              className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-300"
            >
              About Us
            </a>
            <a
              href="#contact"
              onClick={handleLinkClick}
              className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-300"
            >
              Contact
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
