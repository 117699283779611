import { motion } from "framer-motion";
import spa from "../../assets/home/spa-online.webp";

export default function AtlantisOnline() {
  return (
    <div className="h-auto py-20 bg-white">
      <div className="flex flex-col lg:flex-row items-center justify-center gap-10">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center items-center w-screen lg:w-1/3"
        >
          <img src={spa} alt="Atlantis Spa OnLine" height={200} width={200} />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col justify-center w-screen lg:w-1/3 px-5 lg:px-0"
        >
          <h1 className="text-2xl mt-5 font-semibold text-center text-customBlue">
            Atlantis.Online
          </h1>
          {/* <h1 className="text-3xl font-semibold mt-10 text-customDarkText">
            OnLine Reservations for Guests
          </h1> */}
          <p className="text-customDarkText mt-5 text-justify">
            The Atlantis.Online module is a dynamic online reservation platform
            designed to empower customers with seamless appointment scheduling
            capabilities. Through an intuitive and user-friendly interface,
            customers can effortlessly browse available appointment slots and
            reserve their preferred time slots with ease and convenience. This
            module provides customers with the flexibility to book appointments
            at their convenience, eliminating the need for phone calls or
            in-person visits. With real-time availability updates and instant
            confirmation notifications, Atlantis.Online ensures a smooth and
            hassle-free booking experience for customers. Additionally, the
            module allows customers to manage their reservations, make changes,
            and receive timely reminders, enhancing overall customer
            satisfaction and engagement. With its robust features and
            accessibility, Atlantis.Online serves as a valuable tool for
            businesses to streamline their appointment booking process and
            deliver exceptional service to their customers.
            <br />
            &nbsp; The user interface of Atlantis.Online is adaptable and can be
            aligned with the business's brand identity.
          </p>
        </motion.div>
      </div>
    </div>
  );
}
